<template>
  <a-modal
    :visible="visible"
    width="900px"
    title="分配成员角色"
    :mask-closable="false"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
  >
    <a-transfer
      show-search
      :show-select-all="false"
      :titles="['全部项', '已选项']"
      :rowKey="record => record.id"
      :data-source="dataSource"
      :target-keys="targetKeys"
      @change="keys => (targetKeys = keys)"
      v-bind="$attrs"
      :locale="{ searchPlaceholder: '请输入搜索名称' }"
    >
      <template
        #children="{
          direction,
          filteredItems,
          selectedKeys,
          onItemSelectAll,
          onItemSelect,
        }"
      >
        <a-table
          size="small"
          :columns="direction === 'left' ? leftColumns : rightColumns"
          :data-source="filteredItems"
          :row-selection="{
            selectedRowKeys: selectedKeys,
            getCheckboxProps: ({ code }) => ({
              disabled: code === 'SUPER_ADMIN',
            }),
            onSelectAll: (selected, selectedRows, changeRows) =>
              onItemSelectAll(
                changeRows.map(({ key }) => key),
                selected
              ),
            onSelect: ({ key }, selected) => onItemSelect(key, selected),
          }"
          :custom-row="
            ({ key, code }) => ({
              onClick: () => {
                if (code === 'SUPER_ADMIN') return;
                onItemSelect(key, !selectedKeys.includes(key));
              },
            })
          "
        />
      </template>
    </a-transfer>
  </a-modal>
</template>

<script>
import { getCurrentInstance, reactive, toRaw, toRefs, watch } from 'vue';
import { Table, Transfer } from 'ant-design-vue';

export default {
  name: 'TransferModal',
  components: {
    ATransfer: Transfer,
    ATable: Table,
  },
  props: {
    visible: { type: Boolean, default: false },
    leftColumns: { type: Array, default: () => [] },
    rightColumns: { type: Array, default: () => [] },
    initValue: { type: Array, default: () => [] },
    url: { type: String, required: true },
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      dataSource: [],
      targetKeys: props.initValue,
    });
    const handleCancel = () => context.emit('update:visible', false);
    watch(
      () => props.initValue,
      initValue => (state.targetKeys = initValue)
    );
    proxy.$http.get(props.url).then(({ records }) => {
      state.dataSource = records;
    });
    return {
      ...toRefs(state),
      handleCancel,
      // handleSearch:(dir, value)=>{
      //   console.log('search:', dir, value);
      // },
      handleOk: () => {
        context.emit('fnOk', toRaw(state.targetKeys));
        handleCancel();
      },
    };
  },
};
</script>
