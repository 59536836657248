<template>
  <a-space>
    <template v-for="item in options">
      <template v-if="!item.show || item.show(record) !== false">
        <template v-if="item.type === 'update'">
          <a-tooltip :key="item.type" :title="item.label || '编辑'">
            <a-button
              class="iss-btn"
              shape="circle"
              :size="size"
              @click="handleClick(item)"
            >
              <template #icon>
                <component
                  :is="icons[item.icon || 'EditTwoTone']"
                  twoToneColor="#5979F8"
                />
              </template>
            </a-button>
          </a-tooltip>
        </template>
        <template v-else-if="item.type === 'delete'">
          <!--         :disabled="!item.disabled(record) ? !item.disabled(record) : null"  -->
          <a-popconfirm
            :key="item.type"
            :overlayStyle="{ 'z-index': 1070 }"
            placement="topRight"
            title="确认删除吗？"
            @confirm="handleClick(item)"
          >
            <a-tooltip :title="item.label || '删除'">
              <!--
 :disabled="!item.disabled(record) ? !item.disabled(record) : null"
 :class="{ disabledClas: !item.disabled(record) ? !item.disabled(record) : nul }"

-->
              <a-button class="btn-delete iss-btn" shape="circle" :size="size">
                <template #icon>
                  <!--            :twoToneColor="item.disabled(record) ? '#5979F8' : '#d9d9d9'"       -->
                  <component
                    :is="icons[item.icon || 'DeleteTwoTone']"
                    twoToneColor="#5979F8"
                  />
                </template>
              </a-button>
            </a-tooltip>
          </a-popconfirm>
        </template>
        <template v-else>
          <a-tooltip :key="item.type" :title="item.label">
            <a-button
              class="iss-btn"
              shape="circle"
              :size="size"
              @click="handleClick(item)"
            >
              <template #icon>
                <component :is="icons[item.icon]" twoToneColor="#5979F8" />
              </template>
            </a-button>
          </a-tooltip>
        </template>
      </template>
    </template>
  </a-space>
</template>

<script>
import { getCurrentInstance } from 'vue';
import { Divider, Popconfirm, Space, Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'Operation',
  components: {
    ADivider: Divider,
    APopconfirm: Popconfirm,
    ATooltip: Tooltip,
    ASpace: Space,
  },
  props: {
    options: Array,
    record: Object,
    size: {
      type: String,
      default: 'default',
    },
  },

  setup(props) {
    const { proxy } = getCurrentInstance();
    return {
      icons,
      handleClick(item) {
        let permission = item.permission;
        typeof permission === 'function' && (permission = permission());
        typeof permission === 'string' && (permission = [permission]);
        proxy.$has(...permission).then(() => item.fnClick(props.record));
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.btn-delete {
//  &:hover,
//  &:focus {
//    border-color: @primary-color;
//  }
//  &:active {
//    border-color: @primary-color;
//  }
//}
.iss-btn {
  color: @primary-color;
  border: 1px solid #d6e2ff;
  background: #f0f5ff;
}
.disabledClas {
  background: #f5f5f5;
  border-color: #d9d9d9;
}
</style>
