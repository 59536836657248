'use strict';

import http from '@/utils/http';

export default {
  listUrl: '/authority/org/tree',
  getList(code, params) {
    return http.get('/authority/org/tree', { params, code });
  },
  add(code, data) {
    return http.post('/authority/org', data, { code });
  },
  update(code, data) {
    return http.put('/authority/org', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/org', { params, code });
  },
};
