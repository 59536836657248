'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/authority/role/page',
  getPage(code, params) {
    return http.get('/authority/role/page', { params, code });
  },
  getItemById(code, id) {
    return http.get(`/authority/role/${id}`, { code });
  },
  add(code, data) {
    return http.post('/authority/role', data, { code });
  },
  update(code, data) {
    return http.put('/authority/role', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/role', { params, code });
  },
  // check(code, option) {
  //   return http.get(`/authority/role/check/${option}`, { code });
  // },
  getRoleIdByUserId(code, userId) {
    return http.get(`/authority/role/findRoleId/${userId}`, { code });
  },
  saveRoleUser(code, data) {
    return http.post('/authority/role/user', data, { code });
  },
};
